import React from 'react';

import ProductTile from '../ProductTile';
import useGetMHBDealData from '../useGetMHBDealData';

const MyHutBoxDealTile = (): JSX.Element | null => {
  const {
    myHutBoxDealCode, onMyHutBoxDealClick, loading, mhbDeal
  } = useGetMHBDealData();

  const isDisplayMyHutBoxTile = !loading && Boolean(myHutBoxDealCode);

  if (!isDisplayMyHutBoxTile) {
    return null;
  }

  return (
    <div data-testid="my-hut-box-deal-tile">
      {mhbDeal.map((product, index) => (
        <ProductTile key={product.id} product={product} index={index} isMyHutBox={Boolean(myHutBoxDealCode)} clickHandler={onMyHutBoxDealClick} />
      ))}
    </div>
  );
};

export default MyHutBoxDealTile;
