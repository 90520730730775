import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() => createStyles({
  anchorLink: {
    textDecoration: 'none'
  },
  ctaButton: {
    padding: '6px 3rem',
    fontSize: '14px',
    textTransform: 'uppercase'
  },
  preventClick: {
    cursor: 'auto',
    pointerEvents: 'none',
    '&:hover': {
      boxShadow: 'none'
    }
  }
}));
