import { Typography } from '@material-ui/core';
import React from 'react';
import { LEGAL_DISCLAIMER } from '../../builders/pizza/constants';
import styles from './LegalDisclaimer.styles';

const LegalDisclaimer = (): JSX.Element => {
  const classes = styles();

  return (
    <Typography
      className={classes.legalDisclaimer}
      data-testid="legal-disclaimer"
    >
      {LEGAL_DISCLAIMER}
    </Typography>
  );
};

export default LegalDisclaimer;
