import { Categories } from '@pizza-hut-us-development/client-core';

const categoryIDtoDisplayNameMap: { [key: string]: string } = {
  [Categories.PARTYOFONE]: 'party of one'
};

// match every word that is separated by a word boundary character (\b)
const capitalizeReplacerRegex = /\b\w+/g;
const capitalizeReplacerFn = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
export function getCategoryDisplayName(categoryID: string): string {
  const categoryDisplayName: string = categoryIDtoDisplayNameMap[categoryID] ?? categoryID;
  return categoryDisplayName.replace(capitalizeReplacerRegex, capitalizeReplacerFn);
}
