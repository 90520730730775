import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import fontStyles from '../../fontStyles';
import { largeStartBreakpoint, mobileStartBreakpoint, smallMobileStartBreakpoint } from '../../../materialUi/theme';

export default makeStyles((theme) => createStyles({
  subheading: {
    ...fontStyles.h2Mobile,
    alignSelf: 'flex-end',
    margin: '16px 0 0 0',
    fontSize: '24px',
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      ...fontStyles.h2,
      marginTop: '24px',
      lineHeight: '28px'
    }
  },
  ctaButton: {
    margin: '15px 0 24px 0',
    [theme.breakpoints.up(largeStartBreakpoint)]: {
      marginTop: '23px'
    },
    '& button': {
      width: '260px',
      [theme.breakpoints.down(smallMobileStartBreakpoint)]: {
        width: '100%'
      }
    }
  }
}));
