import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useRouter } from 'next/router';
import useStyles from './PizzaTile.styles';
import useMainStyles from '@/common/components/LocalizedProductTile/styles';
import ProductImage from '@/common/components/LocalizedProductTile/ProductImage';
import ProductInfo from '@/common/components/LocalizedProductTile/ProductInfo';
import formattedPrice from '@/common/formattedPrice';
import Routes from '@/router/routes';
import telemetry from '@/telemetry';
import SoldOutTile from '../SoldOutTile';
import { onDealLoversPagePizzaCTAClick } from '@/dataAnalytics/lineup/lineupAnalytics';
import ProductId from '@/common/ProductId';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';

export interface PizzaTileProps {
  id: ProductId;
  name: string;
  imageUrl: string;
  price: number | undefined;
  outOfStock: boolean | undefined;
}

const PizzaTile = (props: PizzaTileProps): JSX.Element => {
  const {
    id,
    name,
    imageUrl,
    price,
    outOfStock
  } = props;
  const productTileClasses = useMainStyles();
  const pizzaTileClasses = useStyles();
  const router = useRouter();
  const formattedUnitPrice = formattedPrice(price);
  const imageDescription = `Pizza tile for ${name}`;
  const analytics = useAnalytics();
  const productId = id.globalId;

  const handleClick = () => {
    telemetry.addCustomEvent('lineup-pizza-click', { productId, productName: name });
    analytics.push(() => onDealLoversPagePizzaCTAClick());

    let queryParams = `&redirectTo=${Routes.LINEUP}`;

    // Redemption flow support
    const redemptionCode = router.query.red?.toString();
    if (redemptionCode) {
      queryParams = `&red=${redemptionCode}`;
    }

    router.push(`${Routes.MENU.PIZZA_BUILDER}?id=${productId}${queryParams}`);
  };

  return outOfStock ? (
    <SoldOutTile
      productId={productId}
      name={name}
      description={imageDescription}
      imageUrl={imageUrl}
    />
  ) : (
    <Grid
      className={`${productTileClasses.grid} ${productTileClasses.gridTemplateWhenNoDropdown}`}
      key={productId}
      data-testid="localized-pizza-product-tile-container"
    >
      <ProductImage imageUrl={imageUrl} description={imageDescription} />
      <ProductInfo name={name} formattedUnitPrice={formattedUnitPrice} />
      <Grid className={productTileClasses.ctaContainer}>
        <Grid className={productTileClasses.sizeDropdownAndCtaButtonWrapper}>
          <Button
            onClick={handleClick}
            color="primary"
            variant="contained"
            className={pizzaTileClasses.ctaButton}
            disableRipple
          >
            Build pizza
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PizzaTile;
