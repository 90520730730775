import React, { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useVersionQuery } from '../../../graphql/hooks/useVersionQuery';
import GET_CATEGORY_DISPLAY_NAME from '../../../graphql/queries/categoryDisplayName';
import { Category } from '../../../graphql/types/Category';
import { RootState } from '@/rootStateTypes';
import { getCategoryDisplayName } from '../utils/getCategoryDisplayName';

interface CategoryDisplayNameProviderProps {
  categoryID: string;
  children: JSX.Element;
}

interface CategoryDisplayNameProviderValues {
  categoryID: string;
  categoryDisplayName: string;
  loadingCategoryName: boolean;
  heading: string | undefined;
  subHeading: string | undefined;
}

const CategoryDisplayNameContext = createContext({} as CategoryDisplayNameProviderValues);

const CategoryDisplayNameProvider = (
  { categoryID, children }: CategoryDisplayNameProviderProps
): JSX.Element => {
  const cmsEnvId = useSelector((state: RootState) => state.domain.cmsEnv.id);

  const {
    data, error, loading
  } = useVersionQuery<Partial<Category>>(
    {
      queryFn: GET_CATEGORY_DISPLAY_NAME,
      storeSpecific: true,
      itemId: undefined,
      options: {
        variables: {
          categoryID,
          envID: cmsEnvId
        }
      },
      nationalOverrides: true
    }
  );

  const { heading, subHeading } = useMemo(() => data || {}, [data]);
  const categoryDisplayName = error || !data?.displayName
    ? getCategoryDisplayName(categoryID)
    : data.displayName;

  return (
    <CategoryDisplayNameContext.Provider
      value={{
        categoryID,
        categoryDisplayName,
        loadingCategoryName: loading,
        heading,
        subHeading
      }}
    >
      {children}
    </CategoryDisplayNameContext.Provider>
  );
};

const useCategoryDisplayNameContext = (): CategoryDisplayNameProviderValues => (
  useContext(CategoryDisplayNameContext)
);

export { CategoryDisplayNameProvider, useCategoryDisplayNameContext };
