import { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';
import { useTemporaryCCContext } from '@/clientCore/temporaryTransformationalHooks/temporaryCCContext';
import useCCGetDealsMenu from '@/clientCore/temporaryTransformationalHooks/useCCGetDealsMenu';
import Routes from '@/router/routes';
import { DisplayableMenuItem } from '@/domain/product/types';
import { MY_HUT_BOX_DEAL_TILE } from '@/deals/constants';

const updateDealPrice = (deal: DisplayableMenuItem, newPrice: number): DisplayableMenuItem => ({
  ...deal,
  sizes: deal?.sizes?.map((size) => ({
    ...size,
    price: newPrice
  }))
});

const MY_HUT_BOX_DEAL_TEXT = 'my hut box';

const useGetMHBDealData = (): { myHutBoxDealCode: string | null; onMyHutBoxDealClick: () => void; loading: boolean; mhbDeal: DisplayableMenuItem[] } => {
  const router = useRouter();
  const { isOptimizelyApiLoading } = useTemporaryCCContext();

  const {
    data,
    isQueryLoading: loading,
    isError
  } = useCCGetDealsMenu({});

  const error = isError
    ? new ApolloError({ errorMessage: 'ccError' })
    : undefined;

  const mhbDeal = data?.deals.filter((deal) => deal?.name?.toLowerCase().includes(MY_HUT_BOX_DEAL_TEXT));
  const dealId = mhbDeal && mhbDeal[0]?.id.split('/');
  const mhbDealCode = dealId ? dealId[dealId.length - 1] : null;
  const myHutBoxPrice = mhbDeal && mhbDeal[0]?.price !== undefined ? mhbDeal[0].price : 0;

  const onMyHutBoxDealClick = () => {
    router.push({
      pathname: Routes.DEALS,
      query: { dealId: `${mhbDealCode}` }
    });
  };

  if (loading || error || isOptimizelyApiLoading) {
    return {
      loading: Boolean(loading),
      onMyHutBoxDealClick,
      myHutBoxDealCode: null,
      mhbDeal: [MY_HUT_BOX_DEAL_TILE]
    };
  }

  return {
    loading: Boolean(loading),
    myHutBoxDealCode: mhbDealCode,
    onMyHutBoxDealClick,
    mhbDeal: [updateDealPrice(MY_HUT_BOX_DEAL_TILE, myHutBoxPrice)]
  };
};

export default useGetMHBDealData;
