import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { CtaButton } from '../../../coreComponents/buttons/CtaButton/CtaButton';
import useStyles from './styles';
import CategoryTitle from '../CategoryTitle';
import { DEAL_LOVERS_HEADING, VLU_HEADING } from '@/menu/categories/constants';

const DEFAULT_SUBHEADING_TEXT = 'Find a restaurant to see our full menu and prices';

export interface CategoryHeaderProps {
  pageDisplayName: string;
  handleCTAClick: () => void;
  customTestId?: string;
  isLocalized?: boolean;
  headingText?: string;
  subHeadingText?: string;
  loadingCategoryName?: boolean;
}

const CategoryHeader = (props: CategoryHeaderProps): JSX.Element => {
  const classes = useStyles();
  const {
    pageDisplayName,
    handleCTAClick,
    isLocalized = false,
    loadingCategoryName = false,
    headingText,
    subHeadingText
  } = props;

  const headingToDisplay = headingText === VLU_HEADING ? DEAL_LOVERS_HEADING : headingText || pageDisplayName;
  const hasSubHeading = !isLocalized || (isLocalized && subHeadingText);

  return (
    <>
      {!loadingCategoryName && (
        <CategoryTitle title={headingToDisplay} />
      )}
      <>
        {hasSubHeading && <Typography variant="h2" className={classes.subheading}>{subHeadingText || DEFAULT_SUBHEADING_TEXT}</Typography>}
        {!isLocalized && (
          <Box className={classes.ctaButton}>
            <CtaButton
              color="primary"
              ariaLabel="Get Started to find your store"
              labelText="Get Started"
              size="large"
              clickHandler={handleCTAClick}
            />
          </Box>
        )}
      </>
    </>
  );
};

export default CategoryHeader;
