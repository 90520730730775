import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { Categories } from '@pizza-hut-us-development/client-core';
import { useDecision } from '@optimizely/react-sdk';
import useStyles from '../../styles';
import { useCategoryDisplayNameContext } from '../context/CategoryDisplayNameProvider';
import { DisplayableMenuItem } from '@/domain/product/types';
import CategoryHeader from '../../../common/components/CategoryHeader';
import {
  createItemAnalytics,
  onNonPizzaMenuPageLoad
} from '../categories.analytics';
import { LocalizedMenuPageSections } from '@/common/components/LocalizedProductTile/LocalizedProductTile.types';
import CaloriesDisclaimer from '@/common/CaloriesDisclaimer/CaloriesDisclaimer';
import Routes from '@/router/routes';
import { ItemAnalytics } from '@/dataAnalytics/analyticsTypes';
import { useApplicationInitialized } from '@/hooks/useApplicationInitialized';
import ProductId from '@/common/ProductId';
import useAnalytics from '@/dataAnalytics/hooks/useAnalytics';
import LocalizedMenuSkeleton from '@/menu/deals/LocalizedMenu/LocalizedMenuSkeleton';
import { useCCGetProducts } from '@/clientCore/temporaryTransformationalHooks/useCCGetProducts';
import LegalDisclaimer from '@/common/LegalDisclaimer/LegalDisclaimer';
import ProductTilesList from './ProductTilesList';
import { getOrInitializeOptimizely } from '../../../../optimizely/optimizely';
import { RootState } from '@/rootStateTypes';

interface LocalizedMenuPageProps {
  fallbackPath?: string;
  showModalOnFallback?: boolean;
  onPageLoad?: (items: ItemAnalytics[]) => void;
}

type ProductOrderVariables = {
  product_order: {
    globalIds: string[];
  };
};

const sortProducts = (data: DisplayableMenuItem[], productOrder: string[], isYumEcomm: boolean): DisplayableMenuItem[] => {
  const productsWithoutDisplayOrder = data?.filter((product) => !product?.displayOrder) ?? [];
  const productsWithDisplayOrder = data?.filter((product) => product?.displayOrder) ?? [];
  const optimizely = getOrInitializeOptimizely();
  const use7dlDrinksSort = optimizely?.isFeatureEnabled('fr-web-3653-7dl-drinks-sort') || false;

  productsWithDisplayOrder?.sort((productA, productB) => {
    if (productA?.displayOrder && productB?.displayOrder) {
      if (productA.displayOrder > productB.displayOrder) return 1;
      if (productA.displayOrder < productB.displayOrder) return -1;

      return productA.name.localeCompare(productB.name);
    }

    return productA?.name.localeCompare(productB?.name);
  });

  if (!use7dlDrinksSort || !isYumEcomm) {
    productsWithoutDisplayOrder?.sort(
      (productWithoutDisplayOrderA, productWithoutDisplayOrderB) => productWithoutDisplayOrderA?.name.localeCompare(
        productWithoutDisplayOrderB?.name
      )
    );
  }

  const products = [...productsWithDisplayOrder, ...productsWithoutDisplayOrder];

  const orderedProducts = productOrder.reduce((acc, value) => {
    const productIndex = products.findIndex((product) => new ProductId(product.id).globalId === value);
    if (productIndex > -1) {
      const [foundProduct] = products.splice(productIndex, 1);
      acc.push(foundProduct);
    }
    return acc;
  }, [] as DisplayableMenuItem[]);

  return [...orderedProducts, ...products];
};

const LocalizedMenuPage = ({
  fallbackPath = Routes.HOME,
  showModalOnFallback = true,
  onPageLoad
}: LocalizedMenuPageProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const appInitialized = useApplicationInitialized();

  const {
    categoryDisplayName,
    heading,
    subHeading,
    categoryID,
    loadingCategoryName
  } = useCategoryDisplayNameContext();

  const [productOrderDecision] = useDecision('fr-web-1507-vlu-product-order');
  const isYumEcomm: boolean = useSelector(
    (state: RootState) => state.coreConfig.isYumEcomm
  );

  const isLineup = categoryID === Categories.LINEUP;
  const productOrder = useMemo(() => (isLineup && productOrderDecision.enabled ? (productOrderDecision.variables as ProductOrderVariables).product_order : { globalIds: [] }), [productOrderDecision, isLineup]);

  const {
    data: ccData,
    loading,
    error,
    storeID
  } = useCCGetProducts({
    // TODO: Remove this toLowerCase once CC puts in their fix CCS-1729
    categoryId: Categories[categoryID.toUpperCase() as keyof typeof Categories]?.toLowerCase() as Categories,
    skip: categoryID === Categories.PIZZA
  });

  const data = (ccData) as LocalizedMenuPageSections;

  const [pageLoadAnalyticsPushed, setPageLoadAnalyticsPushed] = useState(false);
  const sortedProducts: DisplayableMenuItem[] = useMemo(
    () => (data ? sortProducts(data.productsForMainCategory, productOrder.globalIds, isYumEcomm) : []),
    [data, productOrder, isYumEcomm]
  );

  const sortedSubcategories = useMemo(() => {
    if (data && data.subCategories) {
      return data.subCategories.map((s) => ({
        heading: s.heading,
        products: sortProducts(s.products as DisplayableMenuItem[], productOrder.globalIds, isYumEcomm)
      }));
    }
    return [];
  }, [data, productOrder, isYumEcomm]);

  const categoryItemsForAnalytics = useMemo(() => {
    const subcategoryProducts: DisplayableMenuItem[] = sortedSubcategories
      .map(({ products, heading: subcategory }) => products.map((prod) => ({ ...prod, subcategory })))
      .flat();

    return createItemAnalytics(
      [...sortedProducts, ...subcategoryProducts],
      categoryDisplayName
    );
  }, [categoryDisplayName, sortedProducts, sortedSubcategories]);

  const finishedLoadingButNoData = !loading
    && !loadingCategoryName
    && !sortedProducts.length
    && !sortedSubcategories.length;

  const categoryLocalizedItemsCount = categoryItemsForAnalytics.length;

  useEffect(() => {
    if (
      appInitialized
      && categoryLocalizedItemsCount
      && !loadingCategoryName
      && !pageLoadAnalyticsPushed
    ) {
      if (onPageLoad) {
        onPageLoad(categoryItemsForAnalytics);
      } else {
        analytics.push(() => onNonPizzaMenuPageLoad(categoryDisplayName, categoryItemsForAnalytics));
      }

      setPageLoadAnalyticsPushed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoryLocalizedItemsCount,
    loadingCategoryName,
    categoryDisplayName,
    pageLoadAnalyticsPushed,
    appInitialized
  ]);

  if (finishedLoadingButNoData || error || loading) {
    return <LocalizedMenuSkeleton />;
  }

  return (
    <>
      <main
        data-testid={`${categoryID}-localized-menu-page`}
        className={classes.localizedMenuPageMain}
      >
        <section>
          <CategoryHeader
            loadingCategoryName={loadingCategoryName}
            pageDisplayName={categoryDisplayName}
            handleCTAClick={() => null}
            headingText={heading}
            subHeadingText={subHeading}
            isLocalized
            customTestId={`${categoryID}-localized-menu-page-header`}
          />
          <div
            className={classes.tilesGrid}
            data-testid={`${categoryID}-localized-menu-page-content`}
          >
            <ProductTilesList products={sortedProducts} />
          </div>
        </section>
        {sortedSubcategories?.map((subCategory, index) => (
          <section
            key={`subcategory-${index}`}
            data-testid={`subcategory-section-${index}`}
          >
            <Typography variant="h2" className={classes.subcategoryHeading}>
              {subCategory.heading}
            </Typography>
            <div className={classes.tilesGrid}>
              <ProductTilesList products={subCategory.products} />
            </div>
          </section>
        ))}
      </main>
      {isLineup && <LegalDisclaimer />}
      <CaloriesDisclaimer />
    </>
  );
};

export default LocalizedMenuPage;
