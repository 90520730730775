import React from 'react';
import { useDecision } from '@optimizely/react-sdk';

import ProductTile from '@/menu/categories/localized/ProductTile';
import MyHutBoxDealTile from '@/menu/categories/localized/MyHutBoxDealTile';
import { useCategoryDisplayNameContext } from '@/menu/categories/context/CategoryDisplayNameProvider';

import { TProductTilesList } from './types';

const ProductTilesList = ({ products }: TProductTilesList) => {
  const { categoryDisplayName } = useCategoryDisplayNameContext();

  const [myHutBoxDecision] = useDecision('cb-my_hut_box', { autoUpdate: true });
  const [myHutBoxRankDecision] = useDecision('cb-rank_my_hut_box', { autoUpdate: true });

  // TODO: remove the hard coding once we move to YUM Ecomm
  const isPartyOfOneCategory = categoryDisplayName.toLowerCase().includes('party of one');
  const displayMyHutBoxDeal = (myHutBoxDecision?.enabled && isPartyOfOneCategory);
  const isMyHutBoxRankDecisionEnabled = myHutBoxRankDecision?.enabled;

  return (
    <div id="localized-product-tiles-list">
      {(displayMyHutBoxDeal && isMyHutBoxRankDecisionEnabled) && <MyHutBoxDealTile />}
      {products.map((product, index) => (
        <ProductTile product={product} index={index} />
      ))}
      {(displayMyHutBoxDeal && !isMyHutBoxRankDecisionEnabled) && <MyHutBoxDealTile />}
    </div>
  );
};

export default ProductTilesList;
