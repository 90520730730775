import React from 'react';
import { useDispatch } from 'react-redux';

import LocalizedProductTile from '@/common/components/LocalizedProductTile';
import PizzaTile from '@/common/components/LocalizedProductTile/PizzaTile/PizzaTile';
import ProductId from '@/common/ProductId';

import { cartErrorModalDetails } from '@/common/Modal/modalDetails';
import { useCategoryDisplayNameContext } from '@/menu/categories/context/CategoryDisplayNameProvider';
import { openModal } from '@/localization/actions';

import { isPizzaProduct } from '@/domain/product/types';
import { TProductTile } from './types';

const ProductTile = ({
  product, index, isMyHutBox, clickHandler
}: TProductTile) => {
  const dispatch = useDispatch();
  const { categoryDisplayName } = useCategoryDisplayNameContext();

  const showCartErrorModal = () => {
    dispatch(openModal(cartErrorModalDetails));
  };

  return (
    <div id="product-tile">
      {product && isPizzaProduct(product)
        ? (
          <PizzaTile
            key={product.id}
            id={new ProductId(product.id)}
            name={product.name}
            imageUrl={product.imageUrl}
            outOfStock={product.isOutOfStock}
            price={product.price ?? product.sizes?.[0].price}
          />
        )
        : (
          <LocalizedProductTile
            {...product}
            productIndex={index}
            key={`localized-${product.id}`}
            categoryName={categoryDisplayName ?? ''}
            showErrorModal={showCartErrorModal}
            isMyHutBoxProduct={isMyHutBox}
            clickHandler={clickHandler}
          />
        )}
    </div>
  );
};

export default ProductTile;
