import { makeStyles } from '@material-ui/core';
import colors from '../colors';
import fontStyles from '../fontStyles';

const styles = makeStyles((theme) => ({
  legalDisclaimer: {
    ...fontStyles.bodySmall,
    margin: '24px 0 0',
    color: colors.gray600
  }
}));

export default styles;
